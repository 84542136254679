import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="OiM4-v2LUWY?si=bRe3G5G-EHFdmKqP" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          大阪・関西万博住友館への期待感を醸成するトレイラー映像。来場者が自由に森の中を探索し、さまざまな「いのちの物語」を集めていくインタラクティブ体験と、未来に向けて森を育てる植林体験ができることを、イメージCGや別子銅山での撮影素材を使ってリリックビデオ風にまとめました。
        </p>
        <dl className="dlWorkCredit mb-0">
          <dt>Producer：</dt>
          <dd>大田 俊吾（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Director：</dt>
          <dd>落合 正夫（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit">
          <dt>Compositer：</dt>
          <dd>大河原 祐輔（MONTAGE Inc.）</dd>
        </dl>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 7)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/sumitomo_experience_trailer"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/sumitomo_experience_trailer.*"}) {
      publicURL
    }
  }
`;
